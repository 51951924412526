@media (min-width:1700px){
    .rightImg {
        left: -37%;
        width: 65rem;
    }

}

 @media (min-width:1600px){
    .rightImg {
        left: -29%!important;
        width: 65rem;
    }

}
@media (min-width: 1500px){
.rightImg {
    left: -29%!important;
}
}
@media (min-width:1400px){
    .bannerHead {
        font-size: 50px;
        }
        .rightImg {
            left: -36%;
        }
    } 


@media (min-width: 1200px)
{
    /* Start Hover Effect */
    .rolesContainer:hover {
        background: #aec1fb;
        border: 1px solid #aec1fb;
    }
    .continueField:hover {
        color: rgb(37, 87, 167);
        border-color: rgb(103, 146, 240);
        background-color: rgb(238, 241, 254);
    }
    .opptItems:hover {
        background: #1C3276;
        color: #fff !important;
    }
    .opptItems:hover .innerSubtext
    {
        color: #fff;
    }
    .authLinks:hover{
        color:#fff;
    }
}
@media (max-width: 1280px)
{
    .rightImg {
        left: -28%;
        width: 52rem;
        bottom: -20%;
    }
}

@media (max-width: 991px)
{
    .navbar-nav {
        position: absolute;
        width: 100%;
        background-image: linear-gradient(to right, #1C3276 ,#4C69C3, #1C3276);
        padding: 12px;
        left: 0;
        right: 0;
        top: 0px;
    }
    .navheadInner .navbar-nav {
        top: 41px;
        z-index: 9;
    }
    .aboutFounder
    {
        padding: 4rem 0;
    }
    .continue::before
    {
        width: 24%;
    }
    .continue::after
    {
        width: 24%;
    }
    .bannerHead
    {
        font-size: 30px;
    }
    .bannerTxt 
    {
        font-size: 14px;
    }
    .secImg 
    {
        height: 340px;
    }
    .userDetails
    {
        margin: 0;
    }
    .bitesCrad {
        margin-bottom: 20px;
    }
    .noPadding {
        padding-right: 0;
    }
    .faqImgbox {
        display: none;
    }
    .order-last {
        order: 0!important;
    }
    .liveStreame {
        margin: 29px 0 65px;
    }
    .streamImg {
        width: 66%;
    }
    .contectUs_ .memberHomeImage_ {
        padding: 45px 30px;
    }
    .contactInfo_ {
        position: relative;
        margin-bottom: 40px;
    }
    .designLeft_ .boldmainHeading_ {
        font-size: 22px;
    }
    .containerBox {
        margin-top: 30px;
    }
    .navInnerhaed .navbar-toggler {
        position: absolute;
        right: 0;
    }
    /* .rightNav_ #basic-navbar-nav {
        position: absolute;
        width: 100%;
        top: 54px;
        z-index: 9;
    } */
    .rightNav_ {
        display: inline-block;
        width: 100%;
        text-align: left;
    }
}

@media (max-width: 767px)
{
    .continueField
    {
        font-size: 15px;
    }
    .authItems
    {
        margin-right: 10px;
    }
    .workDealing {
        padding: 0 0 0 0;
    }
    .faqcontentBox_ {
        padding: 15px 0 41px 0px;
    }
    .commonContentBox .aboutFounder {
        padding: 3em 0;
    }
    .streamImg {
        width: 100%;
    }
    .commonContentBox .aboutFounder .anotherHeading {
        font-size: 25px;
        padding-right: 0;
    }
    .designbottonContent_ {
        position: relative;
     
    }
    .designContent_ {
        margin: 16px 0 0;
    }
    .imgproduct_ {
        width: 32%!important;
    }
    .designbottonContent_ {
        margin: 9px 0 0;
    }
    .designbtnRight {
        display: inline-block;
        margin: 11px 0 0;
    }
    .activeOpportunity_ .posts {
        display: block;
    }
    .activeOpportunity_ .postsDetails {
        width: 100%;
    }
    .opportunityBtn_ {
        width: 100%;
        text-align: center;
    }
    .opportunityMain_ .subsmallBtnblue_ {
        width: 60%;
    }
    .opportunityMain_ .subsmallBtnblack_{
        width: 50%;
    }
    .activeOpportunity_  .deleteDetails {
        margin-left: 0;
    }
    .submitopportunity {
        width: 90%;
        margin: 0 auto;
    }
}
@media (max-width: 575px){
.streamImgchat {
    bottom: -38px;
    left: 0px;
    
}
.commonContentBox .aboutFounder .anotherHeading {
    line-height: 39px;
}
.rightNav_ {
    justify-content: right;
    margin: 0;
}
}
@media (max-width: 480px){
    .growBusiness_ {
        padding: 0 18px;
        margin: 20px 0!important;
    }

.termspara_ {
    font-size: 14px;
    text-align: justify;
}
.growMainbox_ {
    flex-wrap: wrap;
}
.memberHead_ {
    font-size: 24px;
}
.growbusinessRight_ {
    text-align: left;
    margin: 15px 0 0 72px;
}
.growImage img {
    width: 56px;
    margin-right: 15px;
}
.headingSmall_ {
    font-size: 14px;
}
.otherHeading_ {
    font-size: 24px;
}
.innerSubtext {
    font-size: 16px;
}
.faqcontentBox_ .row-title-text {
    font-size: 16px!important;
}
.contactHead_ {
    font-size: 40px;
}
.contectUs_ .memberHomeImage_ {
    padding: 28px 17px 45px;
}
.imgproduct_ {
    width: 53%;
}
.designcontentTop_ {
 
    align-items: center;
    flex-wrap: wrap;
}
.statusBtn_ {
    padding: 8px 13px;
}
.aboutCopany_ .subsmallBtnblue_ {
    padding: 10px 17px;
    font-size: 12px;
}
.viewProfile_ .headTextmain_ {
    font-size: 26px;
}
.businessBtn .primaryBtnSmall {
    padding: 12px 24px!important;
}
}
